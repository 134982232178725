import styles from './RouterLink.module.scss'
import { FC } from 'react'
import NextLink from 'next/link'
import { Text } from 'src/ui/components/atoms/Text'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { Routes } from 'src/ui/navigation/routes'
import { FontType } from 'src/ui/styles/settings'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { QueryParamKey } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter.model'

interface RouterParam {
  key: QueryParamKey
  value: string
}

interface Props {
  pathname: Routes
  text: string
  // TODO: Creada variante "inlineLink" para que puedan convivir el estilo
  // antiguo de enlace (color negro) con el estilo nuevo hasta que UX pueda
  // establecer el estilo final de todos los enlaces.
  variant: 'primary' | 'secondary' | 'inlineLink'
  /** Si se establece esta propiedad, se ignora el estilo por defecto de variant */
  fontStyle?:
    | FontType
    | {
        mobile: FontType
        tablet?: FontType
        laptop?: FontType
        desktop?: FontType
      }
  'data-testid'?: string
  routerParam?: RouterParam
  onBeforeNavigate?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const RouterLink: FC<Props> = ({
  pathname,
  text,
  variant,
  fontStyle,
  'data-testid': testId,
  routerParam,
  onBeforeNavigate,
  ...rest
}) => {
  const linkStyles =
    variant === 'primary'
      ? styles.primary
      : variant === 'secondary'
        ? styles.secondary
        : styles.inlineLink

  const finalFontStyle = fontStyle
    ? fontStyle
    : variant === 'primary'
      ? 'm-500'
      : 'm-700'

  const { query } = useApplicationRouter()

  const modifiedQuery = {
    ...query,
    ...(isDefined(routerParam) ? { [routerParam.key]: routerParam.value } : {}),
  }

  return (
    <NextLink
      onClick={onBeforeNavigate}
      data-testid={testId}
      href={{
        pathname,
        query: modifiedQuery,
      }}
      className={linkStyles}
      {...rest}
    >
      <Text fontStyle={finalFontStyle}>{text}</Text>
    </NextLink>
  )
}
