import { useEffect, useCallback } from 'react'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  LanguageConfigState,
  useLanguageConfig,
} from 'src/ui/contexts/LanguageConfigContext'
import { DefaultParamValues } from '../useApplicationRouter/buildQueryUtils'

interface RequiredParams {
  [key: string]: string
}

interface RequiredQueryParams {
  getBrowserLanguage: (
    availableLanguages: LanguageConfigState['availableLanguages'],
  ) => string
}

export const useRequiredQueryParams = (
  checkParams: boolean,
): RequiredQueryParams => {
  const { queryUtils, isRouterReady, reload, navigate, query } =
    useApplicationRouter()

  const languageConfig = useLanguageConfig()

  const getBrowserLanguage = (
    availableLanguages: LanguageConfigState['availableLanguages'],
  ) => {
    const browserLanguage = navigator.language
    const isBrowserLanguageInAvailableLanguages = availableLanguages.some(
      availableLanguage => availableLanguage.cbeLanguage === browserLanguage,
    )

    if (isBrowserLanguageInAvailableLanguages) {
      return browserLanguage
    }

    const browserLanguageWithoutRegion = browserLanguage.split('-')[0]

    const filteredLanguage = availableLanguages.find(availableLanguage =>
      availableLanguage.cbeLanguage
        .split('-')[0]
        .includes(browserLanguageWithoutRegion),
    )

    if (isDefined(filteredLanguage)) {
      return filteredLanguage.cbeLanguage
    }

    return DefaultParamValues.locale
  }

  const checkDefaultParams = useCallback(async () => {
    const store = queryUtils.getRawParam('store')
    const country = queryUtils.getRawParam('country')
    const locale = queryUtils.getRawParam('locale')
    const currency = queryUtils.getRawParam('currency')

    let params: RequiredParams = {}

    if (isUndefined(store)) {
      params = {
        ...params,
        store: DefaultParamValues.store,
      }
    }
    if (isUndefined(country)) {
      params = {
        ...params,
        country: DefaultParamValues.country,
      }
    }

    if (isUndefined(currency)) {
      params = {
        ...params,
        currency: DefaultParamValues.currency,
      }
    }

    if (isUndefined(locale)) {
      const browserLanguage = getBrowserLanguage(
        languageConfig.availableLanguages,
      )

      params = {
        ...params,
        locale: browserLanguage,
      }
    }

    if (isEmpty(params)) return

    await navigate.toSameWithoutReload({ ...query, ...params })

    if (isUndefined(locale)) {
      reload()
    }
  }, [navigate, query, queryUtils, reload, languageConfig.availableLanguages])

  useEffect(() => {
    if (!isRouterReady || !checkParams) {
      return
    }

    checkDefaultParams()
  }, [queryUtils, isRouterReady, checkDefaultParams, checkParams])

  return {
    getBrowserLanguage,
  } as RequiredQueryParams
}
