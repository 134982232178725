interface Props {
  variant?: 'light' | 'dark'
}

export function MyBarceloUniqueLogoVertical({ variant = 'dark' }: Props) {
  const fill = `var(--b-color-text-${variant})`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178"
      height="40"
      viewBox="0 0 178 40"
      fill="none"
    >
      <g clipPath="url(#clip0_4084_398)">
        <path
          d="M165.262 5.12307C165.301 5.14802 165.349 5.1605 165.396 5.1605H168.507C168.541 5.1605 168.571 5.15596 168.602 5.14349C168.632 5.13101 168.663 5.114 168.684 5.09359L171.103 2.89014L173.523 0.686686C173.561 0.649262 173.587 0.602766 173.6 0.552868C173.613 0.50297 173.608 0.448536 173.587 0.398638C173.566 0.34874 173.536 0.306781 173.492 0.277295C173.449 0.24781 173.397 0.231934 173.345 0.231934H168.412C168.368 0.231934 168.325 0.244408 168.286 0.261419C168.247 0.27843 168.217 0.311317 168.191 0.345338L166.683 2.54879L165.176 4.75224C165.15 4.7942 165.132 4.83956 165.132 4.88606C165.132 4.93255 165.14 4.97792 165.163 5.02328C165.184 5.06524 165.219 5.09812 165.258 5.12307H165.262Z"
          fill={fill}
        />
        <path
          d="M174.465 9.27714C172.776 7.5704 170.352 6.60986 167.406 6.60986C164.46 6.60986 162.052 7.5738 160.368 9.27714C158.687 10.9839 157.736 13.4346 157.736 16.4148C157.736 19.3951 158.687 21.8242 160.368 23.5276C162.049 25.2264 164.468 26.1869 167.406 26.1869C170.344 26.1869 172.776 25.2264 174.465 23.5276C176.155 21.8288 177.105 19.3826 177.105 16.4148C177.105 13.447 176.155 10.9839 174.465 9.27714ZM170.815 19.9088C170.231 21.019 169.198 21.8753 167.401 21.8753C165.604 21.8753 164.597 21.019 164.018 19.9088C163.44 18.7986 163.306 17.4332 163.306 16.4148C163.306 15.3965 163.44 14.014 164.018 12.9004C164.597 11.7822 165.622 10.9215 167.401 10.9215C169.181 10.9215 170.231 11.7811 170.815 12.9004C171.398 14.0186 171.532 15.3919 171.532 16.4148C171.532 17.4377 171.398 18.7986 170.815 19.9088Z"
          fill={fill}
        />
        <path
          d="M155.043 0.230957H149.717C149.634 0.230957 149.56 0.263844 149.509 0.314876C149.457 0.369311 149.422 0.439621 149.422 0.519005V25.4011C149.422 25.4805 149.456 25.5553 149.509 25.6098C149.56 25.6642 149.634 25.6971 149.717 25.6971H155.043C155.126 25.6971 155.198 25.6642 155.251 25.6098C155.303 25.5553 155.338 25.485 155.338 25.4011V0.523541C155.338 0.444158 155.304 0.369311 155.251 0.319413C155.2 0.264978 155.126 0.235493 155.043 0.235493V0.230957Z"
          fill={fill}
        />
        <path
          d="M69.7545 11.8186C70.7475 11.2346 71.4871 10.5202 71.9801 9.66849C72.4719 8.81682 72.7149 7.82793 72.7149 6.68821C72.7149 4.51311 72.0364 2.89823 70.6583 1.82996C69.2838 0.76169 67.2108 0.230957 64.4194 0.230957H52.6235C52.5413 0.230957 52.4674 0.263844 52.4157 0.314876C52.3641 0.369311 52.3289 0.439621 52.3289 0.519005V25.4011C52.3289 25.4805 52.3629 25.5553 52.4157 25.6098C52.4674 25.6642 52.5413 25.6971 52.6235 25.6971H64.7692C66.8774 25.6971 69.1629 25.2049 70.9213 24.0232C72.6797 22.8382 73.911 20.9636 73.911 18.192C73.911 16.6304 73.5436 15.2865 72.8393 14.2103C72.135 13.133 71.0985 12.3233 69.7545 11.8186ZM58.4269 7.68617V5.0688H63.2925C64.4851 5.0688 65.3573 5.27292 65.9372 5.69479C66.5159 6.11666 66.7929 6.75966 66.7929 7.63628C66.7929 8.73857 66.3738 9.40652 65.7822 9.7989C65.1906 10.1913 64.4217 10.3081 63.7174 10.3081H58.4293V7.68617H58.4269ZM66.8727 20.1255C66.2459 20.6222 65.2998 20.8649 64.0297 20.8649H58.4258V14.6209H64.133C65.369 14.6209 66.2846 14.8885 66.8938 15.4306C67.5031 15.9738 67.8059 16.7869 67.8059 17.8847C67.8059 18.886 67.4948 19.6333 66.868 20.1301L66.8727 20.1255Z"
          fill={fill}
        />
        <path
          d="M93.306 12.0223C93.306 10.2271 92.6099 8.87082 91.2318 7.96925C89.8538 7.06315 87.7843 6.6084 85.0446 6.6084C82.5865 6.6084 80.564 7.10511 79.0732 8.02709C77.5872 8.95361 76.6363 10.3099 76.3206 12.0337C76.3124 12.0711 76.3159 12.1131 76.3206 12.1505C76.33 12.1879 76.3464 12.2253 76.3687 12.2548C76.3898 12.2877 76.4204 12.3138 76.4509 12.3342C76.4849 12.3546 76.5201 12.3671 76.5589 12.3762L79.004 12.7765L81.4491 13.1768C81.4878 13.1859 81.5312 13.1813 81.57 13.1723C81.6087 13.1643 81.6439 13.1473 81.678 13.1224C81.7085 13.0974 81.739 13.0679 81.7555 13.0305C81.7731 12.9976 81.786 12.9557 81.7895 12.9182C81.8717 12.096 82.1487 11.4905 82.6452 11.0936C83.1418 10.6966 83.8508 10.505 84.7922 10.505C85.7336 10.505 86.4344 10.5923 86.9391 10.9098C87.4439 11.2274 87.7256 11.7649 87.7256 12.6676C87.7596 13.331 87.3805 13.7279 86.6668 13.991C85.9531 14.2541 84.9084 14.3925 83.6078 14.5376C81.8447 14.7384 79.8656 14.9595 78.3196 15.7647C76.7772 16.5699 75.6668 17.9602 75.6456 20.4937C75.6456 22.2503 76.2678 23.6747 77.3864 24.659C78.5051 25.6445 80.1262 26.1911 82.1217 26.1911C83.2838 26.1911 84.325 26.0414 85.2582 25.7318C86.1914 25.4234 87.0119 24.9595 87.7291 24.3381V24.4923C87.7291 24.5966 87.7244 24.718 87.7209 24.8677V25.4097C87.7209 25.4891 87.7549 25.564 87.8078 25.6184C87.8594 25.6728 87.9334 25.7057 88.0155 25.7057H93.0102C93.0924 25.7057 93.1663 25.6728 93.218 25.6184C93.2696 25.564 93.3048 25.4937 93.3048 25.4097V12.0189L93.306 12.0223ZM87.7326 17.7833V18.4342C87.7326 19.7327 87.4263 20.6967 86.8076 21.3351C86.1855 21.9736 85.2523 22.2911 83.9904 22.2911C83.0619 22.2911 82.3705 22.1278 81.9163 21.7944C81.4585 21.461 81.2331 20.9597 81.2331 20.2839C81.2331 19.608 81.4268 19.1147 81.8634 18.7359C82.3001 18.356 82.9786 18.0974 83.9376 17.9216C84.1278 17.8888 84.3226 17.8547 84.5163 17.8298C84.7112 17.8003 84.9096 17.7753 85.0997 17.7459C85.5927 17.679 86.0764 17.612 86.5259 17.5202C86.9708 17.4283 87.3864 17.307 87.7315 17.1323V17.7833H87.7326Z"
          fill={fill}
        />
        <path
          d="M144.155 9.24732C143.343 8.38318 142.379 7.7243 141.286 7.28089C140.197 6.83861 138.983 6.6084 137.678 6.6084C134.909 6.6084 132.519 7.63925 130.821 9.38001C129.123 11.1208 128.117 13.5714 128.117 16.4134C128.117 19.2553 129.076 21.7774 130.752 23.4921C132.428 25.2034 134.818 26.1843 137.678 26.1843C139.744 26.1843 141.488 25.7295 142.901 24.8314C144.314 23.9344 145.39 22.586 146.129 20.7987C146.147 20.7568 146.155 20.7148 146.15 20.6694C146.15 20.6275 146.137 20.5821 146.112 20.5447C146.091 20.5073 146.06 20.4732 146.025 20.4483C145.99 20.4233 145.947 20.4063 145.904 20.3984L143.839 20.0604L141.774 19.7225C141.705 19.71 141.64 19.7225 141.584 19.7554C141.528 19.7883 141.481 19.8393 141.455 19.9017C141.26 20.3859 140.799 20.8781 140.167 21.2501C139.537 21.622 138.738 21.8715 137.861 21.8715C136.552 21.8715 135.566 21.554 134.884 20.8985C134.201 20.243 133.817 19.2451 133.712 17.8888H145.702C146.013 17.8888 146.294 17.764 146.497 17.5633C146.7 17.3626 146.825 17.0836 146.825 16.774V16.715C146.83 15.2419 146.596 13.8345 146.147 12.5655C145.697 11.2965 145.028 10.1659 144.163 9.24732L144.155 9.24279V9.24732ZM137.437 14.1679H133.725C133.803 13.6418 134.011 12.8275 134.572 12.1471C135.133 11.4667 136.049 10.92 137.54 10.92C138.564 10.92 139.342 11.1582 139.926 11.6799C140.509 12.206 140.897 13.0158 141.149 14.1679H137.437Z"
          fill={fill}
        />
        <path
          d="M126.204 19.1526C126.182 19.1197 126.152 19.0902 126.117 19.0686C126.083 19.0482 126.047 19.0312 126.004 19.0267L123.667 18.6422L121.33 18.2578C121.291 18.2499 121.252 18.2533 121.214 18.2623C121.175 18.2714 121.14 18.2873 121.11 18.3122C121.08 18.3372 121.049 18.3621 121.033 18.3961C121.015 18.429 120.999 18.4676 120.994 18.505C120.881 19.2728 120.618 20.112 120.056 20.7629C119.494 21.4104 118.635 21.8686 117.326 21.8686C115.909 21.8686 114.945 21.1712 114.336 20.1528C113.727 19.1299 113.477 17.786 113.477 16.4841C113.477 16.0203 113.524 14.6311 114.043 13.353C114.561 12.0795 115.55 10.9194 117.43 10.9194C118.423 10.9194 119.197 11.153 119.759 11.6248C120.32 12.0965 120.675 12.8064 120.822 13.7624C120.826 13.7999 120.843 13.8373 120.86 13.8713C120.881 13.9042 120.908 13.9337 120.938 13.9552C120.968 13.9802 121.007 13.9926 121.041 14.0017C121.08 14.0097 121.119 14.0097 121.157 14.0017L123.555 13.6093L125.954 13.217C126.036 13.2045 126.1 13.158 126.144 13.1001C126.187 13.0378 126.208 12.9629 126.195 12.8835C125.884 10.9761 124.869 9.40656 123.348 8.31221C121.826 7.21899 119.8 6.60547 117.463 6.60547C114.56 6.60547 112.166 7.59095 110.502 9.33965C108.839 11.0884 107.901 13.6014 107.901 16.6531C107.901 19.4576 108.873 21.8414 110.541 23.5198C112.209 25.2016 114.572 26.1791 117.359 26.1791C119.718 26.1791 121.71 25.5576 123.222 24.3964C124.734 23.2363 125.78 21.5329 126.242 19.3703C126.251 19.3329 126.251 19.2909 126.242 19.2535C126.233 19.2161 126.221 19.1786 126.194 19.1446L126.204 19.1537V19.1526Z"
          fill={fill}
        />
        <path
          d="M108.41 6.99284C108.41 6.95088 108.398 6.91346 108.376 6.87603C108.355 6.83861 108.328 6.80912 108.299 6.77964C108.268 6.75469 108.23 6.73768 108.186 6.72521C108.027 6.68325 107.867 6.65376 107.716 6.63788C107.564 6.61747 107.413 6.6084 107.266 6.6084C105.983 6.6084 104.847 6.87944 103.904 7.37615C102.963 7.86832 102.21 8.58277 101.701 9.46733V7.38862C101.701 7.30924 101.667 7.23439 101.614 7.18449C101.562 7.13006 101.488 7.10057 101.406 7.10057H96.6662C96.584 7.10057 96.5113 7.13346 96.4584 7.18449C96.4068 7.23893 96.3716 7.30924 96.3716 7.38862V25.4041C96.3716 25.4835 96.4056 25.5583 96.4584 25.6127C96.5101 25.6672 96.584 25.7001 96.6662 25.7001H101.653C101.735 25.7001 101.808 25.6672 101.86 25.6127C101.912 25.5583 101.947 25.488 101.947 25.4041V17.2854C101.947 15.4993 102.323 14.1419 103.066 13.2278C103.809 12.3092 104.928 11.8341 106.406 11.8001C106.465 11.8001 106.522 11.7796 106.57 11.7456C106.618 11.7127 106.652 11.6662 106.673 11.6118L107.534 9.36186L108.393 7.11192C108.406 7.07449 108.414 7.03253 108.41 6.99057V6.99284Z"
          fill={fill}
        />
        <path
          d="M24.7979 0.230737C22.8751 0.230737 21.2634 1.53262 20.8573 3.27338L20.8479 3.31534C20.8432 3.34029 20.8397 3.36524 20.8303 3.39019L17.2137 20.0074C17.2184 13.6578 17.2219 4.27021 17.2219 4.27021C17.2219 2.18697 15.5926 0.366823 13.441 0.237542C11.402 0.112797 9.66475 1.4521 9.241 3.27225L9.23278 3.31421C9.22808 3.33916 9.22456 3.36411 9.21517 3.38906L5.60797 19.9643V2.47275C5.60797 1.23323 4.56677 0.230737 3.28729 0.230737C2.00781 0.230737 0.966614 1.2321 0.966614 2.47275V25.7105H5.60679V25.6776C5.60679 25.6776 5.61501 25.6685 5.61501 25.6651C5.61501 25.6651 10.1859 4.7862 10.2681 4.34392C10.3937 3.6805 10.8128 3.22575 11.4255 3.22575C12.0864 3.22575 12.5829 3.69752 12.5829 4.34392V25.6901L17.202 25.7071V25.6742H17.2231C17.2231 25.6742 21.7987 4.77826 21.8809 4.34052C22.0065 3.6771 22.4255 3.22235 23.0383 3.22235C23.6992 3.22235 24.1957 3.69411 24.1957 4.34052V25.7037H28.8359V4.13866C28.8359 1.98057 27.0258 0.231871 24.792 0.231871L24.7979 0.230737Z"
          fill={fill}
        />
        <path
          d="M44.9362 0.230957C43.7389 0.230957 42.6942 1.02366 42.4124 2.14636L37.4095 20.9262C37.4095 20.9262 37.4013 20.9137 37.3966 20.9092C37.345 21.1303 37.2839 21.373 37.2194 21.6315L36.9083 22.8041C36.8179 22.9799 36.6665 23.1092 36.4552 23.1092C36.1618 23.1092 35.9235 22.8801 35.9235 22.5955V2.47297C35.9188 1.23345 34.8823 0.230957 33.5993 0.230957C32.3163 0.230957 31.2798 1.23232 31.2798 2.47297V22.718C31.2798 24.4587 32.6402 25.9817 34.4386 26.0951C36.1195 26.1995 37.5574 25.1187 37.9472 23.632L42.8937 5.17426V33.133C42.8937 34.8239 41.6624 36.339 39.9345 36.5896C37.7828 36.8981 35.9164 35.2786 35.9164 33.2589V29.4984C35.9164 28.2589 34.8799 27.2575 33.5957 27.2575C32.3116 27.2575 31.2762 28.2589 31.2762 29.4984V29.9532C31.2762 34.2739 34.9363 37.8053 39.4027 37.8053C43.8692 37.8053 47.5163 34.2818 47.5304 29.9747V2.73947C47.5386 1.35366 46.3718 0.230957 44.9374 0.230957H44.9362Z"
          fill={fill}
        />
        <path
          d="M52.2368 34.5188V30.0654H53.6161V34.5188C53.6161 35.6268 54.4542 36.4365 55.5459 36.4365C56.6375 36.4365 57.4757 35.5837 57.4757 34.5188V30.0654H58.8549V34.5188C58.8549 36.3514 57.5097 37.6511 55.5459 37.6511C53.582 37.6511 52.2368 36.3514 52.2368 34.5188Z"
          fill={fill}
        />
        <path
          d="M65.2321 37.5239V30.0664H66.4681L70.5155 35.266H70.5484V30.0664H71.9276V37.5239H70.6822L66.6454 32.3243H66.6019V37.5239H65.2344H65.2321Z"
          fill={fill}
        />
        <path
          d="M78.4172 37.5239V30.0664H79.7953V37.5239H78.4172Z"
          fill={fill}
        />
        <path
          d="M94.2266 38.0354V39.1763C94.1385 39.1978 93.9284 39.2296 93.6972 39.2296C92.109 39.2296 90.995 38.3132 90.5982 37.6101C90.367 37.6419 90.1686 37.6532 89.9374 37.6532C87.5873 37.6532 85.8125 35.9374 85.8125 33.7963C85.8125 31.6553 87.5873 29.9395 89.9374 29.9395C92.2874 29.9395 94.0622 31.6541 94.0622 33.7963C94.0622 35.2672 93.2124 36.5555 91.9 37.1951C92.1547 37.5897 92.8156 38.0898 93.7418 38.0898C93.9507 38.0898 94.1385 38.0581 94.2277 38.0365L94.2266 38.0354ZM92.6724 33.7952C92.6724 32.3255 91.4598 31.1529 89.9374 31.1529C88.4149 31.1529 87.2012 32.3244 87.2012 33.7952C87.2012 35.2661 88.4149 36.4375 89.9374 36.4375C91.4598 36.4375 92.6724 35.2661 92.6724 33.7952Z"
          fill={fill}
        />
        <path
          d="M99.953 34.5188V30.0654H101.332V34.5188C101.332 35.6268 102.17 36.4365 103.262 36.4365C104.354 36.4365 105.192 35.5837 105.192 34.5188V30.0654H106.571V34.5188C106.571 36.3514 105.226 37.6511 103.262 37.6511C101.298 37.6511 99.953 36.3514 99.953 34.5188Z"
          fill={fill}
        />
        <path
          d="M112.951 37.5239V30.0664H118.499V31.2708H114.329V33.0818H117.616V34.2748H114.329V36.3207H118.609V37.525H112.952L112.951 37.5239Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4084_398">
          <rect
            width="177"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
