import { QueryParamValue } from '../hooks/useApplicationRouter/useApplicationRouter.model'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

const cmsBaseUrl = () => process.env.NEXT_PUBLIC_CMS_BASE_URL

export const generalConditions = () => {
  return `${cmsBaseUrl()}/es-es/mybarcelo/condiciones-generales/?redirect=true&lang=true`
}

export const termsAndConditions = () => {
  return `${cmsBaseUrl()}/es-es/informacion-general/terminos-condiciones/?redirect=auto`
}

export const privacyPolicy = () => {
  return `${cmsBaseUrl()}/es-es/informacion-general/politica-privacidad/?redirect=true&lang=true`
}

export const hotelList = () => {
  return `${cmsBaseUrl()}/es-es/hoteles/?redirect=true&lang=true`
}

export const barceloHome = () => {
  if (isFeatureFlagEnabled('FF_POC_SET_COOKIES')) {
    return 'https://www-staging.barcelo.com/es-es/bhg/ciam-login/'
  }

  return `${cmsBaseUrl()}/?redirect=true`
}

export const hotelListWithDates = (
  startDate: QueryParamValue,
  endDate: QueryParamValue,
) => {
  return `${cmsBaseUrl()}/es-es/listado-hoteles/?startDate=${startDate}&endDate=${endDate}/?redirect=true&lang=true`
}

export const redirectTo404 = () => {
  return `${cmsBaseUrl()}/es-es/404/?redirect=true&lang=auto`
}

export const userPersonalData = (store?: string) => {
  return `${cmsBaseUrl()}/${store ?? 'es-es'}/mybarcelo/area-privada/?redirect=true&lang=true#mydata`
}

export const userMyReservations = (store?: string) => {
  return `${cmsBaseUrl()}/${store ?? 'es-es'}/mybarcelo/area-privada/?redirect=true&lang=true#mis-reservas`
}

export const cookies = () => {
  return `${cmsBaseUrl()}/es-es/informacion-general/politica-cookies/?redirect=true&lang=true`
}

export const legalTerms = () => {
  return `${cmsBaseUrl()}/es-es/informacion-general/?redirect=true&lang=true`
}

export const legalNotice = () => {
  return `${cmsBaseUrl()}/es-es/informacion-general/aviso-legal/?redirect=true&lang=true`
}
