import { FC, ReactNode } from 'react'
import styles from './Text.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { FontType } from 'src/ui/styles/settings'

export type TextColor =
  | 'light'
  | 'mid'
  | 'dark'
  | 'disabled'
  | 'support-success'
  | 'support-success-dark'
  | 'support-error'
  | 'support-error-dark'
  | 'support-info-dark'
  | 'secondary'
  | 'primary'
  | 'fill-neutral-03'
  | 'ocean-100'
  | 'button-secondary'
  | 'myBarcelo-dark'
  | 'unset'

type FontFamily = 'museoSans' | 'barlowCondensed'

interface Props {
  fontStyle:
    | FontType
    | {
        mobile: FontType
        tablet?: FontType
        laptop?: FontType
        desktop?: FontType
      }
  color?: TextColor
  uppercase?: boolean
  capitalize?: boolean
  centered?: boolean
  className?: string
  children?: ReactNode
  as?: 'span' | 'p' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'strong'
  ariaLabel?: string
  ariaHidden?: boolean
  'data-testid'?: string
  title?: string
  family?: FontFamily
  id?: string
}

export const Text: FC<Props> = ({
  fontStyle: style,
  color = 'dark',
  uppercase,
  capitalize,
  centered,
  className,
  children,
  as: Tag = 'span',
  ariaLabel,
  ariaHidden = false,
  'data-testid': testId,
  family = 'museoSans',
  ...rest
}) => {
  const getClassName = () => {
    const fontPrefix = family === 'museoSans' ? '' : 'mybarcelo-'
    if (typeof style === 'string') {
      return classNames(
        styles[`${fontPrefix}mobile-${style}`],
        styles[`${fontPrefix}tablet-${style}`],
        styles[`${fontPrefix}laptop-${style}`],
        styles[`${fontPrefix}desktop-${style}`],
        color !== 'unset' ? styles[`color-${color}`] : undefined,
        uppercase && styles.toUppercase,
        capitalize && styles.capitalize,
        centered && styles.centered,
        family === 'barlowCondensed' && styles.mybarcelo,
        className,
      )
    }

    const mobile = style.mobile
    const tablet = style.tablet || mobile
    const laptop = style.laptop || tablet
    const desktop = style.desktop || laptop

    return classNames(
      styles[`${fontPrefix}mobile-${mobile}`],
      styles[`${fontPrefix}tablet-${tablet}`],
      styles[`${fontPrefix}laptop-${laptop}`],
      styles[`${fontPrefix}desktop-${desktop}`],
      color !== 'unset' ? styles[`color-${color}`] : undefined,
      uppercase && styles.toUppercase,
      capitalize && styles.capitalize,
      centered && styles.centered,
      family === 'barlowCondensed' && styles.mybarcelo,
      className,
    )
  }

  return (
    <Tag
      className={getClassName()}
      data-testid={testId}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      {...rest}
    >
      {children}
    </Tag>
  )
}
