import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/ar'
import 'dayjs/locale/cs'
import 'dayjs/locale/es'
import 'dayjs/locale/es-mx'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/pt'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/tr'
import 'dayjs/locale/ru'
import 'dayjs/locale/pl'
import 'dayjs/locale/sl'
import localeData from 'dayjs/plugin/localeData'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)

// 1548381600000
type Millis = number
export type Format = 'short' | 'long'

export class Time {
  private millis: Millis
  private timezone: {
    keep: boolean
    value: string
  }

  private constructor(millis: number) {
    this.millis = millis
    this.timezone = {
      keep: false,
      value: '',
    }
  }

  private time() {
    let dayjsDate = dayjs(this.millis)
    if (this.timezone.keep) {
      dayjsDate = dayjsDate.utcOffset(this.timezone.value)
    }

    return dayjsDate
  }

  format(template: string): string {
    return this.time().format(template)
  }

  value() {
    return this.millis
  }

  add(amount: number, unit: 'day' | 'month' | 'hour') {
    this.millis = this.time().add(amount, unit).valueOf()
    return this
  }

  endOf(unit: 'day' | 'week' | 'month'): Date {
    return this.time().endOf(unit).toDate()
  }

  endOfTime(unit: 'day' | 'week' | 'month') {
    this.millis = this.time().endOf(unit).valueOf()
    return this
  }

  startOf(unit: 'day' | 'week' | 'month'): Date {
    return this.time().startOf(unit).toDate()
  }

  subtract(amount: number, unit: 'day' | 'month' | 'hour') {
    this.millis = this.time().subtract(amount, unit).valueOf()

    return this
  }

  diff(time: Time, unit: 'day') {
    return this.time().diff(time.toDate(), unit)
  }

  isSame(same: Time, unit: 'day' | 'month' | 'year'): boolean {
    return this.time().isSame(dayjs(same.value()), unit)
  }

  isBefore(time: Time) {
    return this.time().isBefore(dayjs(time.value()))
  }

  isAfter(time: Time) {
    return this.time().isAfter(dayjs(time.value()))
  }

  isBetweenDaysIncluded(start: Time, end: Time): boolean {
    return dayjs(this.millis).isBetween(
      dayjs(start.time()).utc(),
      dayjs(end.time()).utc(),
      'day',
      '[]',
    )
  }

  isToday(): boolean {
    return this.time().isToday()
  }

  toDate(): Date {
    return this.time().toDate()
  }

  toUTCDate(): Date {
    return dayjs.utc(this.millis).toDate()
  }

  toMilliseconds(): number {
    return this.millis
  }

  hour(): number {
    return this.time().hour()
  }

  isSameYear(time: Time): boolean {
    return this.time().isSame(dayjs(time.value()), 'year')
  }

  tz(timezone: string) {
    const formattedDate = this.time()
      .tz(timezone)
      .format('YYYY-MM-DDTHH:mm:ssZ')

    this.timezone = {
      keep: true,
      value: Time.getUTCOffsetFromISOString(formattedDate),
    }

    return this
  }

  static now(): Time {
    return new this(dayjs().valueOf())
  }

  static tomorrow() {
    return new this(dayjs().add(1, 'day').valueOf())
  }

  private fromString(string: string) {
    this.timezone.keep = true
    this.timezone.value = Time.getUTCOffsetFromISOString(string)
  }

  static fromString(string: string, options?: { keepTimezone: boolean }): Time {
    const time = new this(dayjs(string).valueOf())

    if (isDefined(options?.keepTimezone) && options!.keepTimezone) {
      time.fromString(string)
    }

    return time
  }

  static fromStringWithFormat(
    string: string,
    format: string,
    strict = true,
  ): Time {
    return new this(dayjs(string, format, strict).valueOf())
  }

  static fromUTCString(string: string): Time {
    return new this(dayjs.utc(string).valueOf())
  }

  static fromDate(date: Date): Time {
    return new this(dayjs(date).valueOf())
  }

  static getUTCOffsetFromISOString(dateString: string): string {
    const ISO8601_OFFSET_FORMAT = /([+-]\d{2}:\d{2})|(Z)$/

    const [, utcOffset, zeroUtcOffset] = dateString.split(ISO8601_OFFSET_FORMAT)

    if (isDefined(zeroUtcOffset)) {
      return '+00:00'
    }

    return utcOffset
  }

  static setLocale(locale: string) {
    dayjs.locale(locale)
  }

  static seconds(seconds: number): number {
    return seconds * 1000
  }

  static isSameDay(date1?: Date, date2?: Date): boolean {
    if (isUndefined(date1) || isUndefined(date2)) {
      return false
    }

    return dayjs(date1).isSame(dayjs(date2), 'day')
  }

  static isDifferentDay(date1?: Date, date2?: Date) {
    return !this.isSameDay(date1, date2)
  }

  static setNowDateTime(hour: number, minute: number): Date {
    return dayjs().set('hour', hour).set('minute', minute).toDate()
  }
}
