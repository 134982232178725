import { QueryParamValue } from '../hooks/useApplicationRouter/useApplicationRouter.model'
import { QueryParams } from '../hooks/useApplicationRouter/useApplicationRouter.model'
import {
  barceloHome,
  hotelListWithDates,
  redirectTo404,
  userMyReservations,
  userPersonalData,
} from 'src/ui/navigation/cmsLinks'

interface Location {
  replace: (newLocation: string) => void
  toAvailabilityWithReload: (query: QueryParams) => void
  to404: () => void
  toBarceloHome: () => void
  toHotelList: (arrive?: QueryParamValue, depart?: QueryParamValue) => void
  toUserPersonalData: () => void
  toMyReservations: () => void
}

export const location: Location = {
  replace: newLocation => {
    window.location.href = newLocation
  },
  toAvailabilityWithReload: query => {
    const queryParams = new URLSearchParams(
      query as Record<string, string>,
    ).toString()

    location.replace(`/?${queryParams}`)
  },
  to404: () => {
    location.replace(redirectTo404())
  },
  toBarceloHome: () => {
    location.replace(barceloHome())
  },
  toHotelList: (arrive, depart) => {
    location.replace(hotelListWithDates(arrive, depart))
  },
  toUserPersonalData: () => {
    location.replace(userPersonalData())
  },
  toMyReservations: () => {
    location.replace(userMyReservations())
  },
}
