import styles from './SimpleHeader.module.scss'
import { classNames } from 'src/ui/utils/classnames'

import { Image } from 'src/ui/components/atoms/Image'
import { barceloHome } from 'src/ui/navigation/cmsLinks'
import { useMedia } from 'src/ui/hooks/useMedia'

export const SimpleHeader = () => {
  const { media } = useMedia()
  const isLaptopOrDesktop = media === 'desktop' || media === 'laptop'

  return (
    <header className={styles.container}>
      <div className={classNames(styles.header, styles.contentWrapper)}>
        <div className={styles.content}>
          <div className={styles.contentWrapper}>
            <a href={barceloHome()}>
              <Image
                src="/assets/images/main-logo.svg"
                height={isLaptopOrDesktop ? 32 : 24}
                width={91}
                alt="barcelo-logo"
              />
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}
