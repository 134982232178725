export const isFeatureFlagEnabled = (
  featureFlag: keyof FeatureFlags,
): boolean => {
  const featureFlags = getFeatureFlags()
  const isFound = Object.keys(featureFlags).some(
    enabledFeatureFlag => featureFlag === enabledFeatureFlag,
  )
  const isEnabled = featureFlags[featureFlag]

  return isFound && isEnabled
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureFlags {
  // Nuevo revamp de My Barceló
  FF_FEATURE_5847_MY_BARCELO_REVAMP: boolean
  // Poder gestionar reservas
  FF_FEATURE_5691_RESERVATION_MANAGEMENT: boolean
  // Nuevo calendario con restricciones
  FF_FEATURE_5205_CALENDAR_RESTRICTIONS: boolean
  // Nuevo calendario con restricciones
  FF_FEATURE_5205_CALENDAR_RESTRICTIONS_V2: boolean
  // Activa el mensaje y los banners de hotel verse
  FF_FEATURE_6460_HOTEL_VERSE: boolean
  // Activa widget Call Me Back
  FF_FEATURE_5396_CALL_ME_BACK: boolean
  // POC Seteo cookies
  FF_POC_SET_COOKIES: boolean
}

const defaultFeatureFlags: FeatureFlags = {
  FF_FEATURE_5847_MY_BARCELO_REVAMP: true,
  FF_FEATURE_5691_RESERVATION_MANAGEMENT: true,
  FF_FEATURE_5205_CALENDAR_RESTRICTIONS: true,
  FF_FEATURE_5205_CALENDAR_RESTRICTIONS_V2: true,
  FF_FEATURE_6460_HOTEL_VERSE: false,
  FF_FEATURE_5396_CALL_ME_BACK: true,
  FF_POC_SET_COOKIES: false,
}

const getFeatureFlags: () => FeatureFlags = () =>
  process.env.NEXT_PUBLIC_FEATURE_FLAGS
    ? JSON.parse(process.env.NEXT_PUBLIC_FEATURE_FLAGS)
    : defaultFeatureFlags
